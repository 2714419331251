<script setup>
const blocks = [
  { title: '100%', subtitle: 'Gestione documentale compliance' },
  { title: '-70%', subtitle: 'Sulla durata media dei processi aziendali' },
  { title: '-40%', subtitle: 'Sul numero di processi interni' },
  {
    title: '+30%',
    subtitle: 'Di tempo recuperato per attività ad alto valore',
  },
  {
    title: '-25.000',
    subtitle:
      'Ore spese a risolvere errori umani in 1 anno in aziende con una media di 40 contabili',
  },
  {
    title: '+10%',
    subtitle: 'Di risparmio in 3 anni con l’automazione dei processi',
  },
]
</script>

<template>
  <div class="grid-blocks row-40-20 max">
    <div class="container row-80">
      <div class="row">
        <div v-for="(block, index) in blocks" :key="index" class="square">
          <h2 class="title heading-4">{{ block.title }}</h2>
          <p class="subtitle text-2">{{ block.subtitle }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GridBlocks',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
