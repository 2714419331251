<script setup>
defineProps({
  servizi: {
    type: Array,
    default: () => [],
  },
})
</script>

<template>
  <div class="servizi-in-evidenza row-40-20">
    <div class="container row-80-20 max">
      <h2 class="heading-2 in-evidenza-title">
        I nostri servizi, il tuo successo
      </h2>
      <div class="container-flex-sections p-top-4 p-bot-3">
        <div class="left-section">
          <img
            src="@/assets/img/pagine/home/servizi.jpg"
            alt="DigitXS"
            loading="lazy"
          />
        </div>
        <div class="right-section">
          <div v-if="servizi && servizi.length > 0">
            <div v-for="(servizio, index) in servizi" :key="index">
              <nuxt-link
                v-if="servizio.slug"
                :to="
                  localePath({
                    name: 'servizi-slug',
                    params: { slug: servizio.slug },
                  })
                "
              >
                <div
                  v-if="servizio.titolo"
                  class="heading-2-b servizio"
                  :class="{ 'first-servizio': index === 0 }"
                >
                  {{ servizio.titolo }}
                </div>
              </nuxt-link>
            </div>
          </div>
          <div class="section-button">
            <MainButton
              text="Tutti i servizi"
              url="/servizi"
              :internal-link="true"
              :is-black="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiziInEvidenza',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
