<script setup>
import { gsap } from 'gsap'

defineProps({
  hero: {
    type: Object,
    default: () => ({}),
  },
})

const titleRef = ref(null)
const titleRefTwo = ref(null)
const imageRef = ref(null)
const { isHydrating } = useNuxtApp()

onMounted(() => {
  if (isHydrating) {
    setTimeout(animate, 4500)
  } else {
    animate()
  }
})

const animate = () => {
  gsap.fromTo(
    titleRef.value,
    {
      y: '100%',
      autoAlpha: 0,
    },
    {
      y: '0%',
      autoAlpha: 1,
      duration: 1,
      ease: 'Power3.out',
      delay: 0.5,
    },
  )

  gsap.fromTo(
    titleRefTwo.value,
    {
      y: '100%',
      autoAlpha: 0,
    },
    {
      y: '0%',
      autoAlpha: 1,
      duration: 1,
      ease: 'Power3.out',
      delay: 1,
    },
  )
  gsap.fromTo(
    imageRef.value,
    {
      scale: 0,
      autoAlpha: 0,
      transformOrigin: 'center center',
    },
    {
      scale: 1,
      autoAlpha: 1,
      duration: 1.5,
      ease: 'Power3.out',
    },
  )
}
</script>

<template>
  <div class="visibility-fixed-block home-hero row-175-20 p-top-6 max">
    <div class="left-section">
      <div ref="imageRef" class="main-img-container">
        <DefaultPicture
          v-if="hero.sezioneSinistra.immagine.responsiveImage"
          :image="{
            custom: hero.sezioneSinistra.immagine.responsiveImage,
          }"
          :lazy-load="true"
          alt="DigitXS"
        />
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <h1
        v-if="
          hero.sezioneSinistra.titolo && hero.sezioneSinistra.secondaParteTitolo
        "
        class="home-hero__title heading-1"
      >
        <div
          ref="titleRef"
          class="part-one"
          v-html="hero.sezioneSinistra.titolo"
        />
        <div
          ref="titleRefTwo"
          class="part-two"
          v-html="hero.sezioneSinistra.secondaParteTitolo"
        />
      </h1>
      <p
        v-if="hero.sezioneSinistra.testo"
        class="home-hero__description text-2"
        v-html="hero.sezioneSinistra.testo"
      />
    </div>
    <div class="right-section visibility-fixed-block">
      <div class="section-container">
        <DefaultPicture
          v-if="hero.sezioneDestra.immagine.responsiveImage"
          :image="{
            custom: hero.sezioneDestra.immagine.responsiveImage,
          }"
          class="section-image"
          :lazy-load="true"
          alt="DigitXS"
        />
        <!-- eslint-disable vue/no-v-html -->
        <h2
          v-if="hero.sezioneDestra.titolo"
          class="home-hero__title heading-2"
          v-html="hero.sezioneDestra.titolo"
        />
        <!-- eslint-disable vue/no-v-html -->
        <p
          v-if="hero.sezioneDestra.testo"
          class="home-hero__description text-2"
          v-html="hero.sezioneDestra.testo"
        />
        <MainButton
          v-if="hero.sezioneDestra.cta && hero.sezioneDestra.cta.testo"
          :text="hero.sezioneDestra.cta.testo"
          :url="hero.sezioneDestra.cta.link"
          :internal-link="true"
          :is-gray="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeHero',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
