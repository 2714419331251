import { gql } from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'
import { responsiveImageFragment } from '../fragments/images'

export const homeData = gql`
  ${seoFragment}
  ${responsiveImageFragment}
  query HomeData($locale: SiteLocale) {
    homepage(locale: $locale) {
      seo {
        ...SeoFragment
      }
      homeHero {
        sezioneSinistra {
          titolo
          secondaParteTitolo
          testo
          immagine {
            responsiveImage(imgixParams: { fm: webp, q: "50" }) {
              ...ResponsiveImageFragment
            }
          }
        }
        sezioneDestra {
          titolo
          testo
          cta {
            testo
            link
          }
          immagine {
            responsiveImage(imgixParams: { fm: webp, q: "50" }) {
              ...ResponsiveImageFragment
            }
          }
        }
      }
      serviziInEvidenza {
        titolo
        slug
      }
      ambitiInEvidenza {
        titolo
        slug
        testo
        cta {
          testo
          link
        }
      }
      carosello {
        titolo
        slide {
          nome
          immagine {
            responsiveImage(imgixParams: { fm: webp, q: "50" }) {
              ...ResponsiveImageFragment
            }
          }
          descrizione
        }
      }
    }
  }
`
