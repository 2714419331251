<script setup>
defineProps({
  ambiti: {
    type: Object,
    default: () => ({}),
  },
})
</script>

<template>
  <div class="ambiti-in-evidenza row-40-20 max">
    <div class="row-80">
      <h2 class="heading-2 in-evidenza-title p-bot-3">
        Entriamo nel cuore di ogni azienda e risolviamo le specifiche necessità
        di ogni funzione.
      </h2>
      <div v-if="ambiti && ambiti.length > 0">
        <div
          v-for="(ambito, index) in ambiti"
          :key="index"
          :class="{ 'last-ambito': index === ambiti.length - 1 }"
          class="ambito"
        >
          <div class="flex-ambiti">
            <p v-if="ambito.testo" class="ambito-text text-3">
              {{ ambito.testo }}
            </p>
            <div class="ambito-btn">
              <MainButton
                v-if="ambito.cta && ambito.cta.testo && ambito.cta.link"
                :text="ambito.cta.testo"
                :url="ambito.cta.link"
                :internal-link="true"
                :is-black="false"
                class="in-ambiti"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="button-ambiti p-top-4">
        <MainButton
          text="Tutti gli ambiti di intervento"
          url="/ambiti"
          :internal-link="true"
          :is-black="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmbitiInEvidenza',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
