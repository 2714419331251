<script setup>
import { homeData } from '@/graphql/queries/homepage.js'

definePageMeta({
  layout: 'default',
})

const { locale } = useI18n()

const variables = computed(() => ({
  locale: locale.value,
}))

const { data } = await useGraphql(homeData, variables)

if (!data.value.homepage) {
  throw createError({ statusCode: 404 })
}

const page = computed(() => data?.value.homepage)
const hero = computed(() => page?.value.homeHero || {})
const serviziInEvidenza = computed(() => page?.value.serviziInEvidenza || {})
const ambitiInEvidenza = computed(() => page?.value.ambitiInEvidenza || {})

const seo = computed(() => page?.value.seo || {})
useSeo(seo)
</script>

<template>
  <div class="homepage">
    <PagineHomeHero v-if="hero" :hero="hero" />
    <PagineHomeServiziInEvidenza
      v-if="serviziInEvidenza"
      class="p-top-7"
      :servizi="serviziInEvidenza"
    />
    <SwiperSlider
      v-if="
        page.carosello && page.carosello.slide && page.carosello.slide.length
      "
      class="p-top-7"
      :slides="page.carosello.slide"
      :title="page.carosello.titolo"
      type="default-type"
    />
    <AddaBlock class="p-top-7" />
    <PagineHomeGridBlocks class="p-top-7" />
    <PagineHomeAmbitiInEvidenza
      v-if="ambitiInEvidenza"
      class="p-top-7"
      :ambiti="ambitiInEvidenza"
    />
    <PagineHomeEndPageSection class="p-top-7" :with-text="true" />
  </div>
</template>
